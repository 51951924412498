
:root {
  --text-100: #282828;
  --white: #FFFFFF;
  --primary-100: #D9DFFF;
  --primary-200: #b8c1f7;
  --primary-300: #96A2E7;
  --primary-500: #31409B;
  --primary-700: #11164F;
  --black-100: #0B132A;
  --gray-50: #F5F7FA;
  --gray-900: #1D2026;
  --gray-800: #363B47;
  --gray-600: #6E7485;
  --gray-700: #4E5566;
  --gray-500: #8C94A3;
  --gray-400: #A1A5B3;
  --gray-100: #E9EAF0;
  --gray-300: #EEEFF2;
  --success-100: #E1F7E3;
  --success-500: #23BD33;
  --success-600: #1C9729;
  --success-900: #237804;
  --warning-500: #FD8E1F;
  --error-500: #ff4d4f;
  --error-100: #fbd4d6;
  --secondary-100: #FFFCE6;
  --secondary-500: #FDCC41;
  --error-100: #FFF0F0;
}
body {
  font-family: Kanit;
  .content-confirm {
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }
}

@import '~antd/dist/antd.css';
body {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #ff937e;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff937e;
    border-color: #ff937e;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #ff937e;
  }
  .ant-checkbox-wrapper {
    span {
      font-family: Kanit;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #393f4d;
    }
  }
  .ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
  .ant-input {
    height: 50px;
    background: #f2f6fb;
    border-radius: 10px;
    font-family: Kanit;
    font-weight: 300;
    font-size: 16px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
    display: flex;
    align-items: center;
    background: #f2f6fb;
    border-radius: 10px;
  }
  .ant-select-item {
    font-family: Kanit;
    font-weight: 300;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-weight: 300;
  }

  .ant-input:hover,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #b2d6f8;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-input:focus,
  .ant-input-focused {
    border-color: #b2d6f8;
    box-shadow: 0 0 0 2px rgba(178, 214, 248, 0.2);
  }

  .ant-form-item-required {
    position: relative;
    &::before {
      position: absolute;
      right: -14px;
      top: 2px;
    }
  }
  .ant-form-item-explain.ant-form-item-explain-error,
  .ant-form-item-label {
    font-weight: 300;
  }

  .ant-modal-body {
    .wrap-croup-image {
      .group-button {
        margin-top: 20px;
        text-align: center;
        > button {
          margin: 0 10px;
        }
      }
    }
  }
  .ant-radio-wrapper {
    span {
      font-family: Kanit;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
    }
  }
  .ant-radio-checked::after,
  .ant-radio-checked .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #ee6538;
  }
  .ant-radio-inner::after {
    background-color: #ee6538;
  }

  .ant-modal-content {
    background: #ffffff;
    border-radius: 10px;
  }
  .ant-modal-confirm-body {
    > .anticon {
      display: none;
    }
  }
  .ant-modal-confirm-body
    > .anticon
    + .ant-modal-confirm-title
    + .ant-modal-confirm-content {
    margin: 0;
  }
  .ant-modal-confirm .ant-modal-confirm-btns {
    float: unset;
    display: flex;
    justify-content: center;
    .ant-btn {
      background: #ffffff;
      border: 1px solid #ff7a44;
      color: #ff7a44;
      box-sizing: border-box;
      font-family: Kanit;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      box-shadow: 0px 2px 10px #ffe0c2;
      border-radius: 30px;
      height: auto;
      min-width: 100px;
    }
    .ant-btn-primary {
      background: linear-gradient(270deg, #ff967e 0%, #ff7a44 100%);
      color: #fff;
      box-shadow: 0px 2px 10px #ffe0c2;
      border-radius: 30px;
    }
  }
}
